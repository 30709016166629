<template>
  <div>
    <div class="text-center mb-1">{{ $t(`label.${entity}`) }}:</div>
    <div v-if="getChildrenCount(children)" class="symbol-group symbol-hover justify-content-center h-40px">
      <div v-for="child in getChunkedChildren(children)" class="symbol symbol-circle symbol-35">
        <router-link :to="`${entity}/${child.id}`">
          <span class="symbol-label text-hover-primary font-size-h5" :title="child.name">
            {{ getName(child[field]) }}</span>
        </router-link>
      </div>
      <div v-if="getChildrenCount(children) > childIconsNumber"
           class="symbol symbol-circle symbol-light-info symbol-35"
      >
        <span class="symbol-label font-weight-bold" :id="`popover-${entity}-${computedRandomId}`">
          {{ getChildrenCount(children) - childIconsNumber }}+
        </span>
        <b-popover :target="`popover-${entity}-${computedRandomId}`" triggers="click">
          <template #title>{{ $t(`label.${entity}_list`) }}</template>
          <div v-for="child in children" class="text-center">
            <router-link :to="`${entity}/${child.id}`">
              {{ child[popoverField || field] }}
            </router-link>
          </div>
        </b-popover>
      </div>
    </div>
    <div v-else class="d-flex justify-content-center align-items-center h-40px">
      <span class="label label-light-dark label-inline">{{ $t(`label.no_${entity}_yet`) }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    entity: {
      type: String,
      default: ''
    },
    children: {
      type: Array,
      default: () => []
    },
    childIconsNumber: {
      type: Number,
      default: 5
    },
    field: {
      type: String,
      default: 'name'
    },
    popoverField: {
      type: String,
      default: 'name'
    },
    withAcronym: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    computedRandomId() {
      return Math.random().toString(36).substr(2, 5);
    }
  },
  methods: {
    getName(name) {
      return this.withAcronym ? this.getAcronym(name) : name;
    },
    getAcronym(name) {
      let result = '';
      let tokens = name.split(/\s/);
      for (let i = 0; i < tokens.length; i++) {
        result += tokens[i].substring(0, 1).toUpperCase();
      }
      return result;
    },
    getChildrenCount(children) {
      return children?.length || 0;
    },
    getChunkedChildren(children) {
      return children.slice(0, this.childIconsNumber);
    }
  }
}
</script>