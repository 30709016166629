<template>
  <div>
    <app-list request-url="/api/complexes/table" entity="complexes" :update-data="updateDataFlag">
      <template #body="{item: complex}">
        <children-symbols entity="blocks" :children="complex.blocks"/>
      </template>
      <template #actions="{item: complex}">
        <div class="d-flex justify-content-center">
          <router-link
              :to="{name: 'createBlock', query: {complex_id: complex.id}}"
              class="btn btn-text-primary btn-hover-light-primary font-weight-boldest"
          >
            {{ $t('btn.create_block') }}
          </router-link>
        </div>
      </template>
    </app-list>

    <portal to="subheader-toolbar">
      <b-button variant="outline-primary mr-3" @click="syncComplexesAndBlocks">
        {{ $t('btn.sync_complexes_and_blocks') }}
      </b-button>
      <router-link :to="{name: 'createComplex'}">
        <a :href="`/complexes/0/create`" class="btn btn-transparent-white">
          {{ $t('btn.create_complex') }}
        </a>
      </router-link>
    </portal>
  </div>
</template>

<script>
import AppCard from '@/components/AppCard';
import AppList from '@/components/lists/AppList';
import ChildrenSymbols from '@/components/lists/components/ChildrenSymbols';

export default {
  components: {
    AppCard,
    AppList,
    ChildrenSymbols
  },
  data: () => ({
    updateDataFlag: false
  }),
  watch: {
    updateDataFlag(val) {
      if (val) {
        setTimeout(() => {
          this.updateDataFlag = false;
        }, 200)
      }
    }
  },
  methods: {
    syncComplexesAndBlocks() {
      this.$store.dispatch('complexesStore/SYNC').then(() => {
        this.updateDataFlag = true;
      })
    }
  }
}
</script>